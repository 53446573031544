import '../static/css/base.css';
import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//分享
// import Share from 'vue-social-share';
import 'vue-social-share/dist/client.css';
import '../static/plugins/share.js-master/dist/css/share.min.css';
// Vue.use(Share);
Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')