<template>
  <div class="main">
        <Header :curActive="-1"></Header>
        <div class="content">
            <div class="part1">
                <div class="partOne_lbox">
                    <ul class="topul">
                        <li 
                            v-for="(item, i) in news.tabOne" 
                            :key="i" 
                            :class="i == 1 ? 'active' : ''" 
                            @click="topulfn(i)"
                        >
                            {{item.categoryTitle ? item.categoryTitle : ''}}
                        </li>
                        <!-- <li @click="topulfn(1)">中心动态</li>
                        <li @click="topulfn(2)">通知公告</li>
                        <li @click="topulfn(3)">媒体报道</li> -->
                    </ul>
                    <ul class="bottomul">
                        <li :class="j==1 ? 'active' : ''" v-for="(bgItem,j) in news.menuList" :key="j">
                            <p 
                                v-show="bgItem && bgItem.length > 0"
                                v-for="(item,i) in bgItem"
                                :key="i" 
                            >
                                <span class="dot">·</span>
                                <a 
                                    v-if="item.id"
                                    :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" 
                                    target="_blank"
                                    :title="item.contentTitle"
                                >{{item.contentTitle ? item.contentTitle : ''}}</a> 
                            </p>
                            
                        </li>
                    </ul>
                </div>
                <div class="partTwo_lbox">
                    <!-- 轮播图 -->
                    <el-carousel :interval="5000" arrow="always" height="408px" indicator-position="top">
                        <el-carousel-item v-for="(item, i) in carouselList" :key="i" >
                            <a 
                                v-if="item.id"
                                :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id"
                                style="width:100%;height:100%;display:block;"
                                target="_blank"
                                :title="item.contentTitle"
                            >
                                <img :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" :alt="item.contentTitle" style="display:block;width:100%;">
                                <h3>{{ item.contentTitle ? item.contentTitle : '' }}</h3>
                                
                            </a>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <!-- 中间飘窗广告 -->
            <a 
                class="center_ad_box"
                v-if="adData[2] && adData[2].length > 0" 
                v-for="(item,i) in adData[2]" 
                :key="i"
                :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" 
                :title="item.contentTitle" 
                target="_blank"
            >
                <img :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
            </a>
            <!-- 研究项目 -->
            <div class="part2">
                <div class="part2_lbox">
                    <ul class="part2_topul">
                        <!-- <li class="active" @click="partTwoulfn(0)">双碳旗舰研究项目</li>
                        <li @click="partTwoulfn(1)">共同富裕旗舰研究项目</li>
                        <li @click="partTwoulfn(2)">中国经验</li>
                        <li @click="partTwoulfn(3)">全球发展</li> -->
                        <li 
                            v-for="(item, i) in project.tabOne" 
                            :key="i"  
                            :class="i == 0 ? 'active' : ''" 
                            @click="partTwoulfn(i)"
                        >
                            {{item.categoryTitle ? item.categoryTitle : ''}}
                        </li>
                    </ul>
                    <h3 class="part2_title">研究项目</h3>
                    <ul :class="j==0 ? 'part2_underul active' : 'part2_underul'" v-for="(bgItem,j) in project.menuList" :key="j">
                        <!-- 显示文章列表和栏目列表两种情况 -->
                        <li v-if="bgItem && bgItem.length > 0 && !item.categoryType" v-for="(item,i) in bgItem" :key="i">
                            <a 
                                :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id"
                                target="_blank"
                                :title="item.contentTitle"
                            >
                                <div class="part2_underli_imgbox">
                                    <img :src="item.imgSrc && item.imgSrc.length > 0 ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                                </div>
                                <h4>{{item.contentTitle ? item.contentTitle : ''}}</h4>
                                <p>{{item.contentDescription ? item.contentDescription : ''}}</p>
                                <span class="part2_underul_time">{{item.contentDatetime ? item.contentDatetime.substring(0,10) : ''}}</span>
                            </a>
                        </li>
                        <li v-if="bgItem && bgItem.length > 0 && item.categoryType" v-for="(item,i) in bgItem" :key="i">
                            <a 
                                :href="item.contentIsChain == '1' ? item.contentChain : '/research?menuId='+item.id"
                                target="_blank"
                                :title="item.contentTitle"
                            >
                                <div class="part2_underli_imgbox">
                                    <img :src="item.imgSrc && item.imgSrc.length > 0 ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                                </div>
                                <h4>{{item.categoryTitle ? item.categoryTitle : ''}}</h4>
                                <p>{{item.categoryDescrip ? item.categoryDescrip : ''}}</p>
                            </a>
                        </li>
                    </ul>
                </div>
                <!-- 关于我们 -->
                <div class="part2_rbox">
                    <h3 class="part2_rtitle">
                        <a href="/aboutus" style="color: #333333;display:block;width:100%;height:100%;">关于我们</a>
                    </h3>
                    <ul class="part2_rul">
                        <li v-for="(item, i) in aboutUs" :key="i">
                            <a 
                                :href="'/aboutus?menuId='+item.id"
                                target="_blank"
                                :title="item.contentTitle"
                            >
                                <h5>{{item.categoryTitle ? item.categoryTitle : ''}}</h5>
                                <!-- <p>{{item.categoryDescrip ? item.categoryDescrip : ''}}</p> -->
                                <img v-if="i == 0" src="../../assets/imgs/con_icon1.png" alt="">
                                <img v-if="i == 1" src="../../assets/imgs/con_icon2.png" alt="">
                                <img v-if="i == 2" src="../../assets/imgs/con_icon3.png" alt="">
                                <img v-if="i == 3" src="../../assets/imgs/con_icon4.png" alt="">
                                <img v-if="i == 4" src="../../assets/imgs/con_icon5.png" alt="">
                                <img v-if="i == 5" src="../../assets/imgs/con_icon6.png" alt="">
                                <img v-if="i == 6" src="../../assets/imgs/con_icon7.png" alt="">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 交流传播 -->
        <div class="part3">
            <div class="part3-mainbox">
                <h3>交流传播</h3>

                <div class="carousel-a1">
                    <div class="carousel-a5">
                        <div class="carousel-a6">
                            <ul>
                                <li 
                                    v-for="(item,i) in constraction"
                                    :key="i"
                                    @mouseenter="mouseenterfn(i)" 
                                    @mouseleave="mouseleavefn(i)"
                                    style="border: 1px solid #ebebeb;"
                                >
                                    <div  class="carousel-imgbox">
                                        <img :src="item.imgSrc.length > 0 ? imgBase + item.imgSrc[0] : defaultImg1" alt="" />
                                    </div>
                                    <h1>{{item.categoryTitle ? item.categoryTitle : ''}}</h1>
                                    <a v-if="item.id" class="carousel-hover-bgbox" :href="item.categoryDiyUrl ? '/' + item.categoryDiyUrl : '/communicate?menuId='+item.id" :title="item.contentTitle" target="_blank">
                                        <img class="img1" :src="item.imgSrc && item.imgSrc.length > 0 ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                                        <div class="carousel-hoverbox">
                                            <h4>{{item.categoryTitle ? item.categoryTitle : ''}}</h4>
                                            <p>{{item.categoryDescrip ? item.categoryDescrip : ''}}</p>
                                            <img src="../../assets/imgs/btn_arrow1.png" alt="">
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="carousel-a2">
                        <div class="prev">
                            <img src="../../assets/imgs/ban_arrowleft.png" alt="">
                        </div>
                        <div class="carousel-a3"> 
                            <span v-for="(item,i) in Math.ceil(constraction.length/4)" :key="i" :class="i == 0 ? 'one' : ''">{{i}}</span> 
                        </div>
                        <div class="next">
                            <img src="../../assets/imgs/ban_arrowright.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 知识产品 -->
        <div class="part4">
            <div class="part4-mainbox">
                <div class="part4-lbox">
                    <ul class="part4-lbox-topul">
                        <!-- <li @click="knowledgetab(0)" class="active">全球发展报告</li> -->
                        <li 
                            v-for="(item, i) in knowledge.tabOne" 
                            :key="i" 
                            :class="i == 0 ? 'active' : ''" 
                            @click="knowledgetab(i)"
                        >
                            {{item.categoryTitle ? item.categoryTitle : ''}}
                        </li>
                    </ul>
                    <h4 class="part4-title">知识产品</h4>
                    <ul class="part4-lbox-underul">
                        <li :class="j==0 ? 'active' : ''" v-for="(bgItem,j) in knowledge.menuList" :key="j">
                            <!-- 显示栏目列表和文章列表两种情况 -->
                            <!-- 显示文章列表 -->
                            <div v-if="bgItem && bgItem.length > 0 && !item.categoryType" class="part4-lunder-box" v-for="(item,i) in bgItem" :key="i">
                                <div class="part4-lunder-boxOne">
                                    <span>{{item.contentDatetime ? item.contentDatetime.substring(8,10) : ''}}</span>
                                    <span>{{item.contentDatetime ? item.contentDatetime.substring(0,7) : ''}}</span>
                                </div>
                                <div class="part4-lunder-boxTwo">
                                    <div class="part4-lunder-boxTwo-imgbox" style="border: 1px solid #ebebeb;">
                                        <img :src="item.imgSrc && item.imgSrc.length > 0 ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                                    </div>
                                    <div class="part4-lunder-boxTwo-infobox">
                                        <h4>{{item.contentTitle ? item.contentTitle : ''}}</h4>
                                        <p>{{item.contentDescription ? item.contentDescription : ''}}</p>
                                    </div>
                                </div>
                                <a 
                                    class="part4-lunder-detail-button" 
                                    :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id"
                                    target="_blank"
                                >了解详情</a>
                            </div>
                            <!-- 显示栏目列表 -->
                            <div v-if="bgItem && bgItem.length > 0 && item.categoryType" class="part4-lunder-box" v-for="(item,i) in bgItem" :key="i">
                                <div class="part4-lunder-boxOne" style="border-right: 1px solid rgba(0,0,0,0);">
                                    <span>{{item.contentDatetime ? item.contentDatetime.substring(8,10) : ''}}</span>
                                    <span>{{item.contentDatetime ? item.contentDatetime.substring(0,7) : ''}}</span>
                                </div>
                                <div class="part4-lunder-boxTwo">
                                    <div class="part4-lunder-boxTwo-imgbox" style="border: 1px solid #ebebeb;">
                                        <img :src="item.imgSrc && item.imgSrc.length > 0 ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                                    </div>
                                    <div class="part4-lunder-boxTwo-infobox">
                                        <h4>{{item.categoryTitle ? item.categoryTitle : ''}}</h4>
                                        <p>{{item.categoryDescrip ? item.categoryDescrip : ''}}</p>
                                    </div>
                                </div>
                                <a 
                                    class="part4-lunder-detail-button" 
                                    :href="item.contentIsChain == '1' ? item.contentChain : '/products?menuId='+item.id"
                                    target="_blank"
                                >了解详情</a>
                            </div>
                        </li>
                    </ul>

                </div>
                <div class="part4-rbox">
                    <h4>培训咨询</h4>
                    <ul>
                        <li>
                            <a 
                                v-if="teach[0] && teach[0].id"
                                :href="'/consultation?menuId='+ teach[0].id"
                                target="_blank"
                            >
                                <h5>{{(teach.length > 0 && teach[0].categoryTitle) ? teach[0].categoryTitle : ''}} ></h5>
                                <p>{{(teach.length > 0 && teach[0].categoryDescrip) ? teach[0].categoryDescrip : ''}}</p>
                                <img src="../../assets/imgs/px_icon1.png" alt="">
                            </a>
                        </li>
                        <li>
                            <a 
                                v-if="teach[1] && teach[1].id"
                                :href="'/consultation?menuId='+ teach[1].id"
                                target="_blank"
                            >
                                <h5>{{(teach.length > 0 && teach[1].categoryTitle) ? teach[1].categoryTitle : ''}} ></h5>
                                <p>{{(teach.length > 0 && teach[1].categoryDescrip) ? teach[1].categoryDescrip : ''}}</p>
                                <img src="../../assets/imgs/px_icon2.png" alt="">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 相关链接 -->
        <div class="part5">
            <div class="part5-mainbox">
                <h3><a href="/aboutus?menuId=1463671170137751553" target="_blank" style="color: #333333;display:block;width:100%;">{{lastTitle}}</a></h3>
                <div>
                    <a 
                        v-if="partnerList && partnerList.length > 0"
                        v-for="(item,i) in partnerList" 
                        :key="i" 
                        :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" 
                        target="_blank"
                        :title="item.contentTitle"
                    >
                        <img :src="item.imgSrc && item.imgSrc.length > 0 ? imgBase + item.imgSrc[0] : defaultImg1" alt="">
                    </a>
                </div>
            </div>
        </div>
        <!-- 飘窗 左 -->
        <div class="main_center_ad_l_box">
            <div v-if="adData[0].length > 0 && i < 3" class="adItem" v-for="(item,i) in adData[0]" :key="i">
                <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" :title="item.contentTitle" target="_blank">
                    <img :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" border="0">
                </a>
                <div class="ad_close_btn" @click="closeAdFn('l',i)">关闭</div>
            </div>
        </div>
        <!-- 飘窗 右 -->
        <div class="main_center_ad_r_box">
            <div v-if="adData[1].length > 0 && i < 3" class="adItem" v-for="(item,i) in adData[1]" :key="i">
                <a :href="item.contentIsChain == '1' ? item.contentChain : '/detail?docId='+item.id" :title="item.contentTitle" target="_blank">
                    <img :src="(item.imgSrc && item.imgSrc.length > 0) ? imgBase + item.imgSrc[0] : defaultImg1" border="0">
                </a>
                <div class="ad_close_btn" @click="closeAdFn('r',i)">关闭</div>
            </div>
        </div>
        <!-- 回到顶部 -->
        <el-backtop target=".main" :bottom="100">
            <!-- <div
                style="{
                    height: 100%;
                    width: 100%;
                    background-color: #f2f5f6;
                    box-shadow: 0 0 6px rgba(0,0,0, .12);
                    text-align: center;
                    line-height: 40px;
                    color: #1989fa;
                }"
            >
                UP
            </div> -->
            <i class="el-icon-caret-top"></i>
        </el-backtop>
      <Footer></Footer>
  </div>
</template>

<script>

import $ from 'jquery';
import Header from '@/components/header.vue';
import Footer from '@/components/footer.vue';

import img1 from '@/assets/imgs/1.jpg'
import img2 from '@/assets/imgs/2.jpg'
import defaultImg1 from '@/assets/imgs/default.png'
import defaultImg2 from '@/assets/imgs/default2.png'
import defaultImg3 from '@/assets/imgs/default3.png'
import defaultImg4 from '@/assets/imgs/default4.png'

import icon0 from '../../assets/imgs/con_icon1.png';
import icon1 from '../../assets/imgs/con_icon2.png';
import icon2 from '../../assets/imgs/con_icon3.png';
import icon3 from '../../assets/imgs/con_icon4.png';

import api from "@/../static/api/api.js";

export default {
    name: 'index',
    data() {
        return {
            imgBase: this.$store.state.imgBase,
            defaultImg1: defaultImg1,//轮播图默认图片
            defaultImg2: defaultImg2,//研究项目默认图
            defaultImg3: defaultImg3,//交流传播默认图
            news:{//新闻动态
                tabOne: [], //新闻动态tab列表
                menuList: [],//所有的显示列表集
            },
            project:{//研究项目
                tabOne: [], //研究项目tab列表
                menuList: [],//所有的显示列表集
            },
            knowledge:{//知识产品
                tabOne: [], //知识产品tab列表
                menuList: [],//所有的显示列表集
            },
            aboutUs:[], //关于我们
            carouselList:[],//轮播图数据
            constraction: [],//交流传播
            teach: [],//培训咨询
            adData: [],//飘窗数据
            partnerList: [],//合作伙伴数据
            lastTitle: ''//相关链接title
        }
    },
    components:{
        Header,
        Footer
    },
    mounted() {
        // document.title = '首页 | 知识中心'
        this.fetchDataOne();
        this.fetchDataTwo();
        this.fetchDataThree();
        this.fetchDataFour();
        this.fetchDataFive();
        this.fetchDataSix();
        this.fetchDataSeven();
        this.fetchDataEight();
        this.fetchPartnerData();

        this.addCssFn();    
        setTimeout(()=>{
            $('.el-carousel__indicators--horizontal').css({
                'right':'-97px',
                'bottom':'7px'
            })
        },0);
    },
    methods: {
        //新闻动态数据获取
        fetchDataOne(){
            let _this = this;

            // 获取新闻动态tab列表
            let menuId1 = '1462974343012851713';
            let onlyChild1 = false;
            let paltformId1 = '';
            api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                
                if (res.data && res.data.childrenList.length > 0) {

                    // _this.news.tabOne = [];
                    // _this.news.menuList = [];

                    // for(let i=0;i<res.data.childrenList.length;i++){
                        
                    //     let menuId2 = res.data.childrenList[i].id;
                    //     let pageNum1 = 1;
                    //     let pageSize1 = 7;

                    //     api.getArticleList(menuId2, pageNum1, pageSize1).then(ress => {
                    //         // if (ress.data) {
                    //             _this.news.tabOne.push(res.data.childrenList[i]);
                    //             _this.news.menuList.push(ress.data.list);
                    //         // }
                    //     });
                    // }

                    async function fn() {    
                        _this.news.tabOne = [];
                        _this.news.menuList = [];
                        for(let i=0;i<res.data.childrenList.length;i++){
                            await (function(){
                                return new Promise(function(resolve, rej){
                                    let menuId2 = res.data.childrenList[i].id;
                                    let pageNum1 = 1;
                                    let pageSize1 = 8;
                                    api.getArticleList(menuId2, pageNum1, pageSize1).then(ress => {
                                        _this.news.tabOne.push(res.data.childrenList[i]);
                                        _this.news.menuList.push(ress.data.list);
                                        resolve();
                                    });
                                })
                            }())
                        }
                    };
                    fn()
                }
            });
        },
        //研究项目数据获取
        fetchDataTwo(){
            let _this = this;

            // 获取研究项目tab列表
            let menuId1 = '1470215776825348097';
            let onlyChild1 = true;
            let paltformId1 = '';
            api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                
                if (res.data && res.data.childrenList.length > 0) {

                    async function fn() {    
                        _this.project.tabOne = [];
                        _this.project.menuList = [];
                        for(let i=0;i<res.data.childrenList.length;i++){
                            await (function(){
                                return new Promise(function(resolve, rej){
                                    let menuId2 = res.data.childrenList[i].id;
                                    let pageNum1 = 1;
                                    let pageSize1 = 4;
                                    api.getArticleList(menuId2, pageNum1, pageSize1).then(ress => {
                                        _this.project.tabOne.push(res.data.childrenList[i]);
                                        if(ress.data.list.length > 0){
                                            _this.project.menuList.push(ress.data.list);
                                            resolve();
                                        }else{
                                            let menuId2 = ress.data.id;
                                            let onlyChild2 = false;
                                            let paltformId2 = '';
                                            api.getUnderMenuList(menuId2, onlyChild2, paltformId2).then(resss => {
                                                // console.log(resss.data,'resss')
                                                _this.project.menuList.push(resss.data.childrenList);
                                                resolve();
                                            });
                                        }
                                    });
                                })
                            }())
                        }
                    };
                    fn();
                }
            });
        },
        //关于我们数据获取
        fetchDataThree(){
            let _this = this;

            let menuId = '1463669479694827521';
            let onlyChild = false;
            let paltformId = '';
            api.getTopMenuList(menuId, onlyChild, paltformId).then(res => {
                if (res.data && res.data.childrenList.length > 0) {

                    async function fn() {    
                        _this.aboutUs = [];
                        for(let i=0;i<7;i++){
                            await (function(){
                                return new Promise(function(resolve, rej){
                                    let tmpData = res.data.childrenList[i];
                                    let menuId2 = tmpData.id;
                                    let pageNum1 = 1;
                                    let pageSize1 = 1;
                                    api.getArticleList(menuId2, pageNum1, pageSize1).then(ress => {
                                        if (ress.data) {
                                            _this.aboutUs.push(tmpData);
                                            resolve();
                                        }
                                    });
                                })
                            }())
                        }
                    };
                    fn()
                }
            });
        },
        //轮播图数据获取
        fetchDataFour(){
            let _this = this;

            // 获取研究项目tab列表
            let num = 6;
            api.getCarouselList(num).then(res => {
                if (res.data) {
                    _this.carouselList = res.data;

                    const meta = document.createElement('meta');
                    meta.content = res.data[0].contentDescription;
                    meta.name = 'description';
                    document.getElementsByTagName('head')[0].appendChild(meta);

                }
            });
        },
        //交流传播数据获取
        fetchDataFive(){
            let _this = this;

            let menuId1 = '1470216361255473154';
            let onlyChild1 = false;
            let paltformId1 = '';
            api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                if (res.data) {
                    _this.constraction = res.data.childrenList;
                    _this.carouselfn(Math.ceil(res.data.childrenList.length/4));

                    // _this.constraction = res.data.childrenList.filter(function(value){
                    //     return value.childrenList == null;
                    // });
                    // console.log(_this.constraction,'_this.constraction')
                    // _this.carouselfn(Math.ceil(_this.constraction.length/4));
                }
            });

        },
        //知识产品数据获取
        fetchDataSix(){
            let _this = this;

            // 获取知识产品tab列表
            let menuId1 = '1470217565691809794';
            let onlyChild1 = true;
            let paltformId1 = '';
            api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                
                if (res.data && res.data.childrenList.length > 0) {

                    async function fn() {    
                        _this.knowledge.tabOne = [];
                        _this.knowledge.menuList = [];
                        for(let i=0;i<res.data.childrenList.length;i++){
                            await (function(){
                                return new Promise(function(resolve, rej){
                                    let menuId2 = res.data.childrenList[i].id;
                                    let pageNum1 = 1;
                                    let pageSize1 = 2;
                                    api.getArticleList(menuId2, pageNum1, pageSize1).then(ress => {
                                        _this.knowledge.tabOne.push(res.data.childrenList[i]);
                                        
                                        if(ress.data.list.length > 0){
                                            _this.knowledge.menuList.push(ress.data.list);
                                            resolve();
                                        }else{
                                            let menuId2 = ress.data.id;
                                            let onlyChild2 = false;
                                            let paltformId2 = '';
                                            api.getUnderMenuList(menuId2, onlyChild2, paltformId2).then(resss => {
                                                console.log(resss.data,'resss')
                                               _this.knowledge.menuList.push(resss.data.childrenList);
                                                resolve();
                                            });
                                        }

                                    });
                                })
                            }())
                        }
                    };
                    fn();
                    setTimeout(()=>{
                        console.log(_this.knowledge.menuList,'_this.knowledge.menuList')
                    },2000);
                }
            });
        },
        //培训咨询数据获取
        fetchDataSeven(){
            let _this = this;

            let menuId1 = '1470218300600979457';
            let onlyChild1 = true;
            let paltformId1 = '';
            api.getTopMenuList(menuId1, onlyChild1, paltformId1).then(res => {
                if (res.data) {
                    _this.teach = res.data.childrenList;
                }
            });
        },
        //飘窗数据
        fetchDataEight(){
            let _this = this;

            let menuId2 = '1471026066823319553';
            let pageNum1 = 1;
            let pageSize1 = 6;

            api.getArticleList(menuId2, pageNum1, pageSize1).then(res => {
                
                if(res.data.list && res.data.list.length > 0){
                    let tmpArr = [];
                    let left = [];
                    let right = [];
                    let center = [];
                    for(let i=0;i<res.data.list.length;i++){
                        if(res.data.list[i].contentType.substring(0,1) == 'l'){
                            left.push(res.data.list[i]);
                        }
                        if(res.data.list[i].contentType.substring(0,1) == 'r'){
                            right.push(res.data.list[i]);
                        }
                        if(res.data.list[i].contentType.substring(0,6) == 'center'){
                            center.push(res.data.list[i]);
                        }
                    }
                    setTimeout(()=>{
                        _this.adData = [left, right, center];
                        console.log(_this.adData,'_this.adData');
                    },900);
                    
                }
                
            });
        },
        //点击关闭飘窗
        closeAdFn(str,index){
            if(str == 'l'){
                $('.main_center_ad_l_box .adItem').eq(index).css('display','none');
            }
            if(str == 'r'){
                $('.main_center_ad_r_box .adItem').eq(index).css('display','none');
            }
        },
        // 交流传播鼠标hover fn
        mouseenterfn(num){
            $('.carousel-a6 .carousel-hover-bgbox').eq(num).css('display','block');
        },
        // 交流传播鼠标leave fn
        mouseleavefn(num){
            $('.carousel-a6 .carousel-hover-bgbox').eq(num).css('display','none');
        },
        addCssFn(){
            $('.el-carousel__indicators--horizontal').css({'left':'auto','right':'-30px','bottom':'15px'});

            // if(document.body.clientWidth < 1500){
            //     $('.main_center_ad_l_box').css('display','none');
            //     $('.main_center_ad_r_box').css('display','none');
            // }
        },
        //知识产品旁的tab切换
        knowledgetab(num){
            $('.part4 .part4-lbox li').removeClass('active');
            $('.part4 .part4-lbox li').eq(num).addClass('active');

            $('.part4 .part4-lbox-underul li').removeClass('active');
            $('.part4 .part4-lbox-underul li').eq(num).addClass('active');
        },
        //轮播图旁的tab切换
        topulfn(num){
            $('.part1 .topul li').removeClass('active');
            $('.part1 .topul li').eq(num).addClass('active');

            $('.part1 .bottomul li').removeClass('active');
            $('.part1 .bottomul li').eq(num).addClass('active');
        },
        //研究项目模块的tab切换
        partTwoulfn(num){
            $('.part2 .part2_topul li').removeClass('active');
            $('.part2 .part2_topul li').eq(num).addClass('active');

            $('.part2 .part2_underul').removeClass('active');
            $('.part2 .part2_underul').eq(num).addClass('active');
        },
        //交流传播下轮播图
        carouselfn(lastPge){
            var i=4;
            var page=1;
            var timer=null;
            // var jqWidth=$('.carousel-a5').width();
            var jqWidth=1200;
            var page_last=lastPge;

            $('.next').click(function(){ 
                rightMove()
            });

            $('.prev').click(function(){   
                if(page==1){
                    $('.carousel-a6').animate({left:'-='+jqWidth*(page_last-1)},'1000');
                    page=page_last;
                }else{
                    $('.carousel-a6').animate({left:'+='+jqWidth},'1000');
                    page--;
                };
                $('.carousel-a3 span').eq(page-1).addClass('one').siblings('span').removeClass('one');
            });
            
            $('.carousel-a3 span').click(function(){
                $(this).addClass('one').siblings('span').removeClass('one');
                $('.carousel-a6').animate({left:-jqWidth*($('.carousel-a3 span').index(this))+'px'},'1000');
                page=$('.carousel-a3 span').index(this)+1;

            })

            function move(){
                timer=setInterval(function(){
                rightMove();
                },4000);
            };
            // move();
            
            // $('.carousel-a1').mouseover(function(){
            //     clearInterval(timer);
            // });
            // $('.carousel-a1').mouseout(function(){
            //     move();
            // });

            function rightMove(){
                if(page==page_last){
                    $('.carousel-a6').animate({left:'0px'},'1000')
                    page=1;
                }else{
                    $('.carousel-a6').animate({left:'-='+jqWidth},'1000')
                    page++;
                };
                $('.carousel-a3 span').eq(page-1).addClass('one').siblings('span').removeClass('one');
            };
            
        },
        //获取合作伙伴数据
        fetchPartnerData(){
            let _this = this;
            let menuId = '1463671170137751553';
            let pageNum = 1;
            let pageSize = 12;
            let isKY = true;
            _this.ifEmpty = false;
            api.getArticleList(menuId, pageNum, pageSize, isKY).then(res => {
                if (res.data) {
                    _this.partnerList = res.data.hzhb;
                    _this.lastTitle = res.data.title;
                    console.log(res.data,'相关链接')
                    // console.log(res.data.hzhb,'res.data.map');
                }
            });
        },
    }
}
</script>

<style scoped>
.main{
    width:100%;
    height: 100vh;
    overflow-x: hidden;
}

.main_center_ad_l_box{
    display: block;
    position: fixed;
    left: 40px;
    width: 180px;
    overflow: hidden;
    z-index: 1999;
    top: 230px;
}
.main_center_ad_l_box .adItem{
    width:100%;
    margin-bottom: 36px;
    /* height:130px; */
}
.main_center_ad_l_box .adItem a{
    display: block;
}
.main_center_ad_l_box .adItem a img{
    display: block;
    width:100%;
}
.main_center_ad_l_box .adItem .ad_close_btn{
    width:40px;
    text-align: center;
    cursor: pointer;
    float: right;
}
.main_center_ad_r_box{
    display: block;
    position: fixed;
    right: 40px;
    width: 180px;
    overflow: hidden;
    z-index: 1999;
    top: 230px;
}
.main_center_ad_r_box .adItem{
    width:100%;
    margin-bottom: 36px;
    /* height:130px; */
}
.main_center_ad_r_box .adItem a{
    display: block;
}
.main_center_ad_r_box .adItem a img{
    display: block;
    width:100%;
}
.main_center_ad_r_box .adItem .ad_close_btn{
    width:40px;
    text-align: center;
    cursor: pointer;
    float: right;
}
.content{
    width: 1200px;
    /* height: 700px; */
    margin: 0 auto;
}
/* 第一部分 */
.part1{
    width:100%;
    height: 408px;
    margin-top: 32px;
    overflow: hidden;
}
.partOne_lbox{
    width:510px;
    height: 408px;
    float: right;
}
.partOne_lbox .topul{
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #ECECEC;
    font-weight: 700;
    text-align: left;
}
.partOne_lbox .topul li{
    display: inline-block;
    width: 116px;
    height: 42px;
    border-radius: 1px;
    text-align: center;
    line-height: 42px;
    font-size: 18px;
    /* font-family: STXihei; */
    color: #808080;
    cursor: pointer;
}
.partOne_lbox .topul li.active{
    color: #1B70CB;
    background: #F0F3F6;
    font-weight: bold;
}
.partOne_lbox .bottomul{
    width: 100%;
    height: 335px;
    /* overflow-y: scroll; */
    margin-top: 24px;
}
.partOne_lbox .bottomul li{
    width: 100%;
    height: 44px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #1A1A1A;
    line-height: 44px;
    display: none;
    text-align: left;
    
}
.partOne_lbox .bottomul li.active{
    display: block;
    width:100%;
    height: 100%;
}
.partOne_lbox .bottomul li p{
    width:100%;
    height:38px;
    margin-bottom: 4px;
}
.partOne_lbox .bottomul li p:hover{
    background: linear-gradient(90deg, #E9EDF1, #FFFFFF);
}
.partOne_lbox .bottomul li a{
    width:448px;
    height: 38px;
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #1A1A1A;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
}
.dot{
    width: 3px;
    height: 38px;
    margin-right: 18px;
    font-weight: bold;
    font-size: 20px;
    color: #666666;
    float:left;
}
.partTwo_lbox{
    width:604px;
    height: 408px;
    float: left;
    border:1px solid #ebebeb;
}
/* 轮播图 */
.el-carousel__indicators--horizontal{
    right:10px!important;
    bottom: 300px!important;
}
.el-carousel__item h3 {
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #FFFFFF;
    line-height: 60px;
    margin: 0;
    width: 94%;
    height: 80px;
    /* background: linear-gradient(90deg, #1B78DD, #1BA4BE); */
    background: url(../../assets/imgs/bg_under.png) repeat-x;
    position: absolute;
    left:0;
    top:330px;
    text-align: left;
    padding-left: 21px;
    padding-right: 21px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

/* 第二部分 */
.part2{
    height: 770px;
    width:100%;
    margin-top: 80px;
}
.part2_lbox{
    width: 845px;
    height: 100%;
    float: left;
    position: relative;
}
.part2_topul{
    width: 698px;
    height: 32px;
    border-bottom: 1px solid #ECECEC;
    text-align: left;
    margin-left: 147px;
}
.part2_topul li{
    display: inline-block;
    height: 30px;
    border-bottom: 2px solid rgba(0,0,0,0);
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 700;
    color: #A1A1A1;
    line-height: 30px;
    margin-right:40px;
    cursor: pointer;
}
.part2_topul li.active{
    border-bottom: 2px solid #1B70CB;
    color: #1967B6;
}
.part2_title{
    position: absolute;
    top:0;
    left:0;
    font-size: 26px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 30px;
}
.part2_underul{
    width: 100%;
    height: 692px;
    margin-top: 30px;
    overflow: hidden;
    display: none;
}
.part2_underul.active{
    display: block;
    border: 1px solid #E8E8E8;
    box-shadow: 0px 5px 5px 0px rgba(223, 226, 230, 0.75);
}
.part2_underul li{
    width: 92%;
    height: 138px;
    background: #FFFFFF;
    cursor: pointer;
    border-bottom: 1px solid #E8E8E8;
    overflow: hidden;
    margin:34px 0 0 34px;
}
.part2_underul li:hover{
    /* border-bottom: 2px solid #1B70CB; */
    
}
.part2_underul li a{
    display: block;
    width: 100%;
    height: 100%;
}
.part2_underul li:nth-of-type(4){
    border-bottom: 0;
}
.part2_underli_imgbox{
    width: 130px;
    height: 86px;
    overflow: hidden;
    border:1px solid #e8e8e8;
    float:left;
}
.part2_underli_imgbox img{
    width: 100%;
}
.part2_underul h4{
    width: 560px;
    /* height: 18px; */
    font-size: 18px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 19px;
    margin-left: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    float: left;
}
.part2_underul p{
    width: 560px;
    height: 51px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    margin-left: 25px;
    text-align: left;
    margin-top: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    float:left;
}
.part2_underul_time{
    width: 490px;
    height: 12px;
    font-size: 12px;
    font-family: Arial;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    margin-left: 157px;
    margin-top: 16px;
    display: block;
    text-align: left;
    float: left;
}
/* part2右侧部分 */
.part2_rbox{
    width: 300px;
    float: right;
}
.part2_rtitle{
    width:100%;
    font-size: 26px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 26px;
    text-align: left;
    margin-bottom: 37px;
}
.part2_rul{
    width: 100%;
    height: 694px;
    background: #FFFFFF;
    border-left: 1px solid #E8E8E8;
    border-right: 1px solid #E8E8E8;
    border-top:1px solid #E4E5E7;
    overflow: hidden;
}
.part2_rul li{
    width: 100%;
    /* height: 171px; */
    height: 97px;
    border-bottom:1px solid #E4E5E7;
    border-top:1px solid #fff;
    position: relative;
    overflow: hidden;
}
.part2_rul li:hover{
    background: #F3F5F7;
}
.part2_rul li img{
    width: 42px;
    position: absolute;
    top:32px;
    left:29px;
}
.part2_rul li a{
    display: block;
    width:100%;
    height:100%;
}

.part2_rul li a h5{
    padding:42px 54px 20px 92px;
    text-align: left;
    font-size: 16px;
    line-height:16px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.part2_rul li a p{
    height: 50px;
    line-height:26px;
    margin:0 41px 0 92px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #666666;
}

/* 第三部分 */
.part3{
    height: 490px;
    width: 100%;
    margin-top: 70px;
    background: url('../../assets/imgs/bg2.png') no-repeat bottom;
    background-size: contain;
}
.part3-mainbox{
    width: 1200px;
    height: 100%;
    margin:0 auto;
    overflow: hidden;
}
.part3-mainbox h3{
    width: 100%;
    height: 25px;
    font-size: 26px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    text-align: left;
    margin-bottom: 25px;
}

/* 交流传播轮播转盘 */
.carousel-a1 { 
    width:1200px;
}
.carousel-a2 { 
    height:35px; 
    text-align: center;
    margin-top: 20px;
}
.carousel-a2 h1 { height:35px; line-height:35px; padding-left:10px; float:left;}
.carousel-a3 { 
    display:inline-block; 
    height: 30px;
    vertical-align: middle;
}
.carousel-a3 span { 
    width: 6px;
    height: 6px;
    background: #E0E1E2;
    border-radius: 50%; 
    float:left;  
    text-indent:-9999px; 
    margin:0 6px; 
}
.carousel-a3 span.one { 
    width: 24px;
    height: 6px;
    background: #1B70CB;
    border-radius: 2px;
}
.carousel-a5 { 
    width:1200px; 
    height:310px; 
    overflow:hidden; 
    position:relative;  
    border-top:none;
}
.carousel-a5 ul li { 
    float:left; 
    margin-right:24px; 
    width:280px;
    height:270px;
    position: relative;
}
.carousel-a6 { 
    width:2500px; 
    position:absolute; 
    top:0; 
    left:0;
}
.carousel-a6 h1 { 
    width:232px;
    height: 17px;
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 17px;
    margin: 25px auto 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
    text-align: left;
}
.carousel-a6 h1 a { color:#aaa; }
.carousel-a6 img{ width:100%;}
.carousel-imgbox{
    height: 190px;
    width:100%;
    overflow: hidden;
    display: block;
}
.carousel-hover-bgbox{
    width: 100%;
    height:100%;
    position: absolute;
    top: 0;
    left:0;
    display: none;
    cursor: pointer;
}
.carousel-hover-bgbox .img1{
    width: 100%;
    height:100%;
    position: absolute;
    top: 0;
    left:0;
}
.carousel-hoverbox{
    width: 100%;
    height:100%;
    position: absolute;
    top: 0;
    left:0;
    /* background: linear-gradient(0deg, #1B78DD, #1BA4BE); */
    background: #1B70CB;
    opacity: 0.9;
}
.carousel-hoverbox h4{
    width: 208px;
    /* height: 56px; */
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #FFFFFF;
    line-height: 30px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 40px auto 28px;
}
.carousel-hoverbox p{
    width: 210px;
    height: 90px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FEF8F8;
    line-height: 30px;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.carousel-hoverbox img{
    width: 31px;
    height: 7px;
    margin-top: 38px;
    float: left;
    margin-left: 36px;
}
.carousel-a2 .prev{
    width: 30px;
    height: 30px;
    background: #D3D8DF;
    border-radius: 50%;
    display:inline-block;
    margin-right:13px;
}
.carousel-a2 .prev img{
    width: 8px;
    display: block;
    margin: 9px auto 0;
}
.carousel-a2 .next{
    width: 30px;
    height: 30px;
    background: #D3D8DF;
    border-radius: 50%;
    display:inline-block;
    margin-left:13px;
}
.carousel-a2 .next img{
    width: 8px;
    display: block;
    margin: 9px auto 0;
}
.carousel-a2 .prev:hover{
    background: #A3A9B2;
}
.carousel-a2 .next:hover{
    background: #A3A9B2;
}
/* 第四部分 */
.part4{
    width:100%;
    height: 655px;
    background: url('../../assets/imgs/bg22.png') no-repeat center;
    background-size: cover;
}
.part4-mainbox{
    width:1200px;
    height: 100%;
    margin:0 auto;
    overflow: hidden;
}
.part4-lbox{
    width: 846px;
    height: 100%;
    float: left;
    position: relative;
}
.part4-lbox-topul{
    width: 688px;
    height: 32px;
    line-height: 32px;
    border-bottom: 1px solid #99A6B5;
    margin-left: 158px;
    text-align: left;
    margin-top: 86px;
    margin-bottom: 39px;
}
.part4-lbox-topul li{
    display: inline-block;
    height: 31px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 700;
    color: #99A6B5;
    line-height: 32px;
    margin-right: 29px;
    cursor: pointer;
}
.part4-lbox-topul li:last-child{
    margin-right: 0;
}
.part4-lbox-topul li.active{
    color: #1B70CB;
    border-bottom: 2px solid #1B70CB;
}
.part4-title{
    width: 108px;
    height: 25px;
    font-size: 26px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #F8FAFD;
    line-height: 25px;
    position: absolute;
    top: 94px;
    left:0;
}
.part4-lunder-box{
    width: 100%;
    height: 160px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    margin-bottom: 22px;
}
.part4-lunder-boxOne{
    height: 80px;
    width:120px;
    text-align: center;
    line-height: 100px;
    border-right: 1px solid #E6E7E8;
    border-top:1px solid #fff;
    margin-top: 35px;
    float: left;
}
.part4-lunder-boxOne span:first-child{
    width: 100%;
    height: 24px;
    font-size: 24px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #1A1A1A;
    line-height: 24px;
    text-align: center;
    display: block;
    margin-top: 12px;
}
.part4-lunder-boxOne span:last-child{
    width: 100%;
    height: 14px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #989898;
    line-height: 14px;
    text-align: center;
    margin-top: 16px;
    display: block;
}
.part4-lunder-boxTwo{
    width:540px;
    height: 80px;
    float: left;
    margin-top: 38px;
    margin-left: 17px;
    border-right: 1px solid #E6E7E8;
}
.part4-lunder-boxTwo-imgbox{
    width: 118px;
    height: 80px;
    overflow: hidden;
    float: left;
}
.part4-lunder-boxTwo-imgbox img{
    display: block;
    width: 100%;
}
.part4-lunder-boxTwo-infobox{
    width: 366px;
    height: 100px;
    float: left;
    margin-left: 19px;
}
.part4-lunder-boxTwo-infobox h4{
    width: 326px;
    /* height: 16px; */
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #1A1A1A;
    line-height: 17px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
}
.part4-lunder-boxTwo-infobox p{
    width: 100%;
    height: 56px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #808080;
    line-height: 30px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 14px;
    text-align: left;
}
.part4-lunder-detail-button{
    width: 118px;
    height: 42px;
    background: #EAECED;
    border-radius: 1px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #404244;
    line-height: 42px;
    float: left;
    margin-top: 64px;
    margin-left: 26px;
    cursor: pointer;
}
.part4-lbox-underul{
    width:100%;
    height: 346px;
    overflow: hidden;
}
.part4-lbox-underul li{
    display: none;
}
.part4-lbox-underul li.active{
    display: block;
}
.part4-rbox{
    width: 300px;
    height: 502px;
    float: right;
}
.part4-rbox h4{
    width: 100%;
    height: 26px;
    font-size: 26px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #FFFFFF;
    line-height: 26px;
    text-align: left;
    margin-top: 87px;
    margin-bottom: 46px;
}
.part4-rbox ul{
    width: 100%;
    height: 344px;
    background: #1967B6;
    opacity: 0.8;
    border-radius: 1px;
    overflow: hidden;
}
.part4-rbox ul li{
    width: 220px;
    height: 172px;
    margin: 0 auto;
    border-top:1px solid #1967B6;
    overflow: hidden;
    position: relative;
}
.part4-rbox ul li:first-child{
    border-bottom: 1px solid #468ACE;
}
.part4-rbox ul li a{
    width: 100%;
    height: 100%;
    display: block;
}
.part4-rbox ul li a h5{
    width: 168px;
    height: 16px;
    text-align: left;
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #FFFFFF;
    line-height: 16px;
    margin-top: 63px;
    margin-left: 44px;
    margin-bottom: 15px;
}
.part4-rbox ul li:last-child a h5{
    width: 168px;
    height: 16px;
    text-align: left;
    font-size: 16px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #FFFFFF;
    line-height: 16px;
    margin-top: 39px;
    margin-left: 44px;
    margin-bottom: 15px;
}
.part4-rbox ul li a p{
    width: 181px;
    height: 54px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #FFFFFF;
    line-height: 27px;
    opacity: 0.9;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-left: 44px;
}
.part4-rbox ul li a img{
    width:30px;
    position: absolute;
    top:62px;
    left:0;
}
.part4-rbox ul li:last-child a img{
    width:30px;
    position: absolute;
    top:37px;
    left:0;
}
/* 相关链接部分 */
.part5{
    width:100%;
    height:382px;
    background-color: #fff;
}
.part5-mainbox{
    width: 1200px;
    height: 100%;
    margin:0 auto;
    overflow: hidden;
}
.part5-mainbox h3{
    width:100%;
    height: 26px;
    font-size: 26px;
    /* font-family: STXihei; */
    font-weight: bold;
    color: #333333;
    line-height: 26px;
    text-align: center;
    margin-top: 67px;
}
.part5-mainbox div{
    width: 100%;
    overflow: hidden;
    margin-top:38px;
}
.part5-mainbox div a{
    width: 180px;
    height: 88px;
    background: #FFFFFF;
    border: 1px solid #E8E8E8;
    display: inline-block;
    float: left;
    margin-right: 21px;
    margin-bottom: 39px;
    overflow: hidden;
}
.part5-mainbox div a:nth-child(6){
    margin-right: 0;
}
.part5-mainbox div a:nth-child(12){
    margin-right: 0;
}
.part5-mainbox div a:nth-child(18){
    margin-right: 0;
}
.part5-mainbox div a:nth-child(24){
    margin-right: 0;
}
.part5-mainbox div a:nth-child(30){
    margin-right: 0;
}
.part5-mainbox div a:nth-child(36){
    margin-right: 0;
}
.part5-mainbox div a:nth-child(42){
    margin-right: 0;
}
.part5-mainbox div a:nth-child(48){
    margin-right: 0;
}
.part5-mainbox div a:hover{
    box-shadow: 0px 5px 5px 0px rgba(223, 226, 230, 0.75);
}
.part5-mainbox div a img{
    width: 152px;
    height: 64px;
    display: block;
    margin: 13px auto 0;

}
.center_ad_box{
    width: 100%;
    display: block;
    text-align: center;
    margin-top:50px;
}
.center_ad_box img{
    display: block;
    width:100%;
}




</style>
