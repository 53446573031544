import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		// imgBase: "http://www.cikd.org/ms/file/getimage/"
		imgBase: '/ms/file/getimage/',
	},
	mutations: {},
	actions: {},
	modules: {},
});
