import http from './axios';

// let normalUrl = 'api'; //普通啥也不加的
let normalUrl = '/ckidfront'; //上线时替换
window.normalUrl = normalUrl;
export default {
	getTopMenuList(menuId, onlyChild, paltformId) {
		return http(
			'get',
			normalUrl +
				'/CkidIndexController/getIndexMenuById?menuId=' +
				menuId +
				'&onlyChild=' +
				onlyChild +
				'&paltformId=' +
				paltformId,
			'json'
		);
	},
	getUnderMenuList(menuId, onlyChild, paltformId) {
		return http(
			'get',
			normalUrl +
				'/CkidIndexController/getAllMenuById?menuId=' +
				menuId +
				'&onlyChild=' +
				onlyChild +
				'&paltformId=' +
				paltformId,
			'json'
		);
	},
	getArticleList(menuId, pageNum, pageSize, isKY = false) {
		return http(
			'get',
			normalUrl +
				'/CkidIndexController/docListByMenuId?menuId=' +
				menuId +
				'&pageNum=' +
				pageNum +
				'&pageSize=' +
				pageSize +
				'&isKY=' +
				isKY,
			'json'
		);
	},
	getSpecialArticleList(menuId, pageNum, pageSize, year) {
		return http(
			'get',
			normalUrl +
				'/CkidIndexController/specialDocListByMenuId?menuId=' +
				menuId +
				'&pageNum=' +
				pageNum +
				'&pageSize=' +
				pageSize +
				'&year=' +
				year,
			'json'
		);
	},
	getCarouselList(num) {
		return http(
			'get',
			normalUrl + '/CkidIndexController/getLunBoPic?num=' + num,
			'json'
		);
	},
	searchDataList(keyWord, pageNum, pageSize, projectId) {
		return http(
			'get',
			normalUrl +
				'/CkidIndexController/indexSearch?keyWord=' +
				keyWord +
				'&pageNum=' +
				pageNum +
				'&pageSize=' +
				pageSize +
				'&projectId=' +
				projectId,
			'json'
		);
	},
	getArticleDetail(docId) {
		return http(
			'get',
			normalUrl + '/CkidIndexController/docByDocId?docId=' + docId,
			'json'
		);
	},
	doOrderEmail(email) {
		return http(
			'get',
			normalUrl + '/CkidIndexController/subscription?email=' + email,
			'json'
		);
	},

	upmarketsearchAllBtn(data) {
		return http(
			'post',
			normalUrl + '/list/listCcompany/getIndicatorByCoder',
			'json',
			data
		);
	},
};
