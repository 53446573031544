<template>
    <div class="main-footer">
        <div class="footer-topbox">
            <div class="footer-top-main-box">
                <!-- <div class="footer-top-box1">
                    
                    <a class="footer-top-nav first" href="/index">首页</a>
                    <a 
                        v-for="(item,i) in menuList" 
                        :parentId="item.topId" 
                        :menuId="item.id" 
                        :key="i" 
                        class="footer-top-nav" 
                        :href="'/'+item.categoryDiyUrl"
                    >{{item.categoryTitle}}</a>
                </div> -->
                <div class="footer-top-box2">
                    <div @click="clickBtnFn" class="footer-top-search-button">订阅</div>
                    <el-input class="footer-top-search-box" @keyup.enter.native="clickBtnFn" v-model="footer_searchVal" placeholder="输入电子邮箱订阅"></el-input>
                </div>
                <img class="footer-top-left-logo" src="../assets/imgs/logo2.png" alt="中国国际发展知识中心">
            </div>
        </div>
        <span class="footer-border"></span>
        <div class="footer-underbox">
            <span class="span1">京公网安备 11030102010789号</span>
            <img class="span1" style="margin-left:30px;" src="../assets/imgs/icp.png" alt="">
            <span class="span1">版权所有&nbsp;&nbsp; 中国国际发展知识中心&nbsp;&nbsp; 京ICP备17048856号</span>

            <div class="footer-underbox-share-box">
                <!-- 两种调用方式都行 -->
                <!-- <div class="social-share" data-sites="wechat,twitter,linkedin,weibo"></div>   -->
                <div class="social-share2" data-initialized="true">

                    <a href="javascript:void(0);" class="social-share-icon icon-wechat" style="position:relative;">
                        <img v-if="footerHover.hover1" src="../assets/imgs/wechat_footer.png" alt="微信">
                        <img v-if="!footerHover.hover1" src="../assets/imgs/wechat_hover.png" alt="微信">
                        <div class="wechat_showbox" v-if="!footerHover.hover1">
                            <img src="../assets/imgs/cikd_wechat.jpg" alt="微信">
                        </div>
                    </a>
                    <a href="https://twitter.com/CIKD_org" target="_blank" class="social-share-icon icon-twitter">
                        <img v-if="footerHover.hover2" src="../assets/imgs/twitter_footer.png" alt="推特">
                        <img v-if="!footerHover.hover2" src="../assets/imgs/twitter_hover.png" alt="推特">
                    </a>
                    <a href="https://www.linkedin.com/company/cikd/about/" target="_blank" class="social-share-icon icon-linkedin">
                        <img v-if="footerHover.hover3" src="../assets/imgs/linkedin_footer.png" alt="领英">
                        <img v-if="!footerHover.hover3" src="../assets/imgs/linkedin_hover.png" alt="领英">
                    </a>
                    <a href="https://weibo.com/p/1001066451429540" target="_blank" class="social-share-icon icon-weibo">
                        <img v-if="footerHover.hover4" src="../assets/imgs/wb_footer.png" alt="微博">
                        <img v-if="!footerHover.hover4" src="../assets/imgs/wb_hover.png" alt="微博">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

export default {
    name: 'index',
    data() {
        return {
            footer_searchVal: '',
            footerHover:{
                hover1: true,
                hover2: true,
                hover3: true,
                hover4: true,
            },
            menuList: []//获取栏目列表
        }
    },
    mounted() {
        this.fetchData();
        this.footerShareFn();
    },
    methods: {
        //点击订阅按钮订阅
        clickBtnFn(){
            let _this = this;

            if(!this.footer_searchVal){
                this.$message.error('请输入订阅邮箱！');
                return;
            }
            api.doOrderEmail(this.footer_searchVal).then(res => {
                if(res.data == '订阅成功！'){
                    this.footer_searchVal = '';
                    this.$message({
                        message: '恭喜你，订阅成功！',
                        type: 'success'
                    });
                }else{
                    this.$message.error(res.data);
                }
            });
        },
        fetchData(){
            let _this = this;
            let menuId = '1453963868773634049';
            let onlyChild = true;
            let paltformId = '1453963868773634049';
            api.getTopMenuList(menuId, onlyChild, paltformId).then(res => {
                if (res.data) {
                    // console.log(res);
                    _this.menuList = res.data.childrenList;
                }
            });
        },
        //   分享函数
        footerShareFn(){
            //hover效果
            $('.social-share2 .icon-wechat').off('mouseenter');
            $('.social-share2 .icon-wechat').on('mouseenter',() => {
                this.footerHover.hover1 = false;
            })
            $('.social-share2 .icon-wechat').off('mouseleave');
            $('.social-share2 .icon-wechat').on('mouseleave',() => {
                this.footerHover.hover1 = true;
            })

            $('.social-share2 .icon-twitter').off('mouseenter');
            $('.social-share2 .icon-twitter').on('mouseenter',() => {
                this.footerHover.hover2 = false;
            })
            $('.social-share2 .icon-twitter').off('mouseleave');
            $('.social-share2 .icon-twitter').on('mouseleave',() => {
                this.footerHover.hover2 = true;
            })

            $('.social-share2 .icon-linkedin').off('mouseenter');
            $('.social-share2 .icon-linkedin').on('mouseenter',() => {
                this.footerHover.hover3 = false;
            })
            $('.social-share2 .icon-linkedin').off('mouseleave');
            $('.social-share2 .icon-linkedin').on('mouseleave',() => {
                this.footerHover.hover3 = true;
            })

            $('.social-share2 .icon-weibo').off('mouseenter');
            $('.social-share2 .icon-weibo').on('mouseenter',() => {
                this.footerHover.hover4 = false;
            })
            $('.social-share2 .icon-weibo').off('mouseleave');
            $('.social-share2 .icon-weibo').on('mouseleave',() => {
                this.footerHover.hover4 = true;
            })

            var config = {
                url : window.location.href,// 网址，默认使用 window.location.href
                source : '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
                title : '', // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
                description : '', // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
                image : '', // 图片, 默认取网页中第一个img标签
                sites : ['wechat','twitter','linkedin','weibo'], // 启用的站点
                disabled : [], // 禁用的站点
                wechatQrcodeTitle : '微信扫一扫：分享', // 微信二维码提示文字
                wechatQrcodeHelper : '',
                target : '_blank' //打开方式
            };
            // socialShare('.social-share2',config);
        }
    }
}
</script>

<style scoped>
.main-footer{
    width:100%;
    height: 273px;
    background: url('../assets/imgs/bg3.png') no-repeat center;
    background-size: cover;
}
.footer-topbox{
    width: 100%;
    height: 138px;
    border-bottom: 1px solid #171717;
}
.footer-border{
    display:block;
    width: 100%;
    height: 1px;
    border-bottom:1px solid #494B4D;
}
.footer-top-main-box{
    width: 1200px;
    height: 189px;
    margin: 0 auto;
    position: relative;
    border-top:1px solid rgba(0,0,0,0);
}
.footer-top-box1{
    width:100%;
    margin-top:60px;
}
.footer-top-nav{
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #FFFFFF;
    line-height: 30px;
    margin-left: 44px;
}
.footer-top-nav.first{
    margin-left: 560px;
}
.footer-top-box2{
    width:100%;
    height: 40px;
    margin-top:28px;
}
.footer-top-search-box{
    width: 204px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 1px;
    margin-left: 30px;
    float: right;
}
.footer-top-search-button{
    width: 48px;
    height: 40px;
    /* background: linear-gradient(90deg, #2FA82C, #14CC89); */
    background: #217AD5;
    border-radius: 1px;
    float: right;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}
.footer-top-left-logo{
    width: 243px;
    position: absolute;
    top:32px;
    left:0;
}
.footer-underbox{
    width: 1200px;
    height: 81px;
    margin:0 auto;
    position: relative;
}
.footer-underbox .span1{
    float: right;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #FFFFFF;
    margin-top:30px;
}
.footer-underbox-share-box{
    position: absolute;
    top: 28px;
    left:0;
}
.social-share2 a{
    width: 30px;
    height: 30px;
    background: #F4F6F8!important;
    border-radius: 50%;
    display: inline-block;
    line-height: 30px;
    text-align: center;
    vertical-align: middle;
    transition: background 0.6s ease-out 0s;
    margin-right: 8px;
}
.social-share2 a>img{
    display: block;
    margin: 5px auto 0;
}
.wechat_showbox{
    position: fixed;
    bottom: 50px;
    z-index: 500;
    width: 10rem;
    margin-bottom: 1rem;
    border: 0 solid #fff;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    /* display: none; */
}
.wechat_showbox img{
    display: block;
    width: 100%;
}






</style>
