<template>
  <div class="main-header">
        <!-- <a style="width:100%;display:block;" href="http://www.gov.cn/zhuanti/zggcddescqgdbdh/index.htm" target="_blank">
            <img style="display:block;width:100%;" src="../assets/imgs/newbanner.png" alt="">
        </a> -->
    <div class="header">
        <div class="header-topbox">
            <div class="header-top-main-box">
                <div class="header-top-sm-box">
                    <div class="header-top-search-button" @click="searchBtnFn">
                        <img src="../assets/imgs/input.png" alt="">
                    </div>
                    <el-input class="header-top-search-box" @keyup.enter.native="searchBtnFn" v-model="searchVal" placeholder="请输入内容"></el-input>
                    <!-- <a class="header-top-words_back" href="http://archive.cikd.org/" target="_blank">返回旧版</a> -->
                    <a class="header-top-words_button" href="http://en.cikd.org/" target="_blank">English</a>
                    <!-- <a class="header-top-words_button" href="http://archive.cikd.org/english/" target="_blank">English</a> -->
                    <span class="header-top-split">|</span>
                    <a class="header-top-words_button active" href="javascript:void(0);">中文</a>   
                </div>
                <img class="header-top-left-logo" src="../assets/imgs/logo3.png" alt="中国国际发展知识中心">
            </div>
        </div>
        <div class="header-underbox">
            <div class="header-underbox-nav-box">
                <a :class="curActive == -1 ? 'header-underbox-nav active' : 'header-underbox-nav'" href="/index">首页</a>
                <div class="header_hoverDivBox_main" @mouseenter="headerHoverFn(1,i)" @mouseleave="headerHoverFn(2,i)" v-for="(item,i) in menuList" :key="i" style="display:inline-block;float:left;position:relative;">
                    <!-- :href="'/'+item.categoryDiyUrl" -->
                    <a 
                        :class="curActive != -1 ? (curActive == i ? 'header-underbox-nav active' : 'header-underbox-nav') : 'header-underbox-nav'" 
                        href="javascript:void(0);"
                    >{{item.categoryTitle}}</a>
                    <div v-if="item.childrenList && item.childrenList.length > 0" class="header_hoverDivBox">
                        <a 
                            class="header_hoverDivBox_item"
                            v-for="(sitem,j) in item.childrenList" 
                            :key="j" 
                            :href="'/'+item.categoryDiyUrl + '?menuId=' + sitem.id"
                        >{{sitem.categoryTitle}}</a>
                        <img src="../assets/imgs/header_bg.png" alt="">
                    </div>
                </div>
                
                <!-- <a class="header-underbox-nav" href="javascript:void(0);">新闻动态</a>
                <a class="header-underbox-nav" href="javascript:void(0);">研究项目</a>
                <a class="header-underbox-nav" href="javascript:void(0);">交流传播</a>
                <a class="header-underbox-nav" href="javascript:void(0);">知识产品</a>
                <a class="header-underbox-nav" href="javascript:void(0);">培训咨询</a>
                <a class="header-underbox-nav" href="javascript:void(0);">关于我们</a> -->
            </div>
            <div class="header-underbox-share-box">
                <!-- 两种调用方式都行 -->
                <!-- <div class="social-share" data-sites="wechat,twitter,linkedin,weibo"></div>   -->
                <div class="social-share" data-initialized="true">
                    <a href="javascript:void(0);" class="social-share-icon icon-wechat" style="position:relative;">
                        <img v-if="shareHover.hover1" src="../assets/imgs/wechat.png" alt="微信">
                        <img v-if="!shareHover.hover1" src="../assets/imgs/wechat_hover.png" alt="微信">
                        <div class="wechat_showbox" v-if="!shareHover.hover1">
                            <img src="../assets/imgs/cikd_wechat.jpg" alt="微信">
                        </div>
                    </a>
                    <a href="https://twitter.com/CIKD_org" target="_blank" class="social-share-icon icon-twitter">
                        <img v-if="shareHover.hover2" src="../assets/imgs/twitter.png" alt="推特">
                        <img v-if="!shareHover.hover2" src="../assets/imgs/twitter_hover.png" alt="推特">
                    </a>
                    <a href="https://www.linkedin.com/company/cikd/about/" target="_blank" class="social-share-icon icon-linkedin">
                        <img v-if="shareHover.hover3" src="../assets/imgs/linkedin.png" alt="领英">
                        <img v-if="!shareHover.hover3" src="../assets/imgs/linkedin_hover.png" alt="领英">
                    </a>
                    <a href="https://weibo.com/p/1001066451429540" target="_blank" class="social-share-icon icon-weibo">
                        <img v-if="shareHover.hover4" src="../assets/imgs/wb.png" alt="微博">
                        <img v-if="!shareHover.hover4" src="../assets/imgs/wb_hover.png" alt="微博">
                    </a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery';
import api from "@/../static/api/api.js";

export default {
    name: 'index',
    props:['curActive'],
    data() {
        return {
            searchVal: '',
            shareHover:{
                hover1: true,
                hover2: true,
                hover3: true,
                hover4: true,
            },
            menuList: [],//获取栏目列表
        }
    },
    mounted() {
        this.fetchData();
        this.shareFn();

    },
    methods: {
        fetchData(){
            let _this = this;
            let menuId = '1453963868773634049';
            let onlyChild = false;
            let paltformId = '1453963868773634049';
            api.getTopMenuList(menuId, onlyChild, paltformId).then(res => {
                if (res.data) {
                    // console.log(res);
                    _this.menuList = res.data.childrenList;
                    // console.log(res.data.childrenList,'res.data.childrenList')
                    // $('.header-underbox-nav-box').find('.header-underbox-nav').eq(1).addClass('active');
                }
            });
        },
        //鼠标hover显示下拉框
        //num 1:hover,2:leave 
        headerHoverFn(num,index){
            if(num == 1){
                $('.header_hoverDivBox_main .header_hoverDivBox').removeClass('active');
                $('.header_hoverDivBox_main .header_hoverDivBox').eq(index).addClass('active');
            }
            if(num == 2){
                $('.header_hoverDivBox_main .header_hoverDivBox').removeClass('active');
            }
        },
        //点击搜索按钮
        searchBtnFn(){
            if(!this.searchVal){
                this.$message('请输入搜索内容！');
            }else{
                window.location.href = '/searchpage?keyword=' + this.searchVal;
            }
        },
        //分享函数
        shareFn(){
            //hover效果
            $('.social-share .icon-wechat').off('mouseenter');
            $('.social-share .icon-wechat').on('mouseenter',() => {
                this.shareHover.hover1 = false;
            })
            $('.social-share .icon-wechat').off('mouseleave');
            $('.social-share .icon-wechat').on('mouseleave',() => {
                this.shareHover.hover1 = true;
            })

            $('.social-share .icon-twitter').off('mouseenter');
            $('.social-share .icon-twitter').on('mouseenter',() => {
                this.shareHover.hover2 = false;
            })
            $('.social-share .icon-twitter').off('mouseleave');
            $('.social-share .icon-twitter').on('mouseleave',() => {
                this.shareHover.hover2 = true;
            })

            $('.social-share .icon-linkedin').off('mouseenter');
            $('.social-share .icon-linkedin').on('mouseenter',() => {
                this.shareHover.hover3 = false;
            })
            $('.social-share .icon-linkedin').off('mouseleave');
            $('.social-share .icon-linkedin').on('mouseleave',() => {
                this.shareHover.hover3 = true;
            })

            $('.social-share .icon-weibo').off('mouseenter');
            $('.social-share .icon-weibo').on('mouseenter',() => {
                this.shareHover.hover4 = false;
            })
            $('.social-share .icon-weibo').off('mouseleave');
            $('.social-share .icon-weibo').on('mouseleave',() => {
                this.shareHover.hover4 = true;
            })

            var config = {
                url : window.location.href,// 网址，默认使用 window.location.href
                source : '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
                title : '', // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
                description : '', // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
                image : '', // 图片, 默认取网页中第一个img标签
                sites : ['wechat','twitter','linkedin','weibo'], // 启用的站点
                disabled : [], // 禁用的站点
                wechatQrcodeTitle : '微信扫一扫：分享', // 微信二维码提示文字
                wechatQrcodeHelper : '',
                target : '_blank' //打开方式
            };
            // socialShare('.social-share',config);
        }
    }
}
</script>

<style scoped>
.main-header{
    width:100%;
    /* height: 100%; */
}
/* 头部 */
.header{
    width: 100%;
    height: 195px;
    /* background: linear-gradient(90deg, #2164C8, #1B99C6); */
    background: #0e5197;
}
.header-topbox{
    width: 100%;
    height: 134px;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    background: #fff;
}
.header-top-main-box{
    width: 1200px;
    height: 132px;
    margin: 0 auto;
    position: relative;
    border-top:1px solid rgba(0,0,0,0);
}
.header-top-left-logo{
    width: 268px;
    position: absolute;
    top:32px;
    left:0;
}
.header-top-sm-box{
    width: 100%;
    height: 40px;
    margin-top: 47px;
}
.header-top-words_button{
    height: 40px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #9E9E9E;
    line-height: 40px;
    float: right;
}
.header-top-words_back{
    height: 40px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #1B70CB;
    line-height: 40px;
    float: right;
    margin-left: 30px;
}
.header-top-words_button.active{
    color: #1B70CB;
}
.header-top-split{
    height: 40px;
    font-size: 14px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #9E9E9E;
    line-height: 40px;
    margin: 0 8px;
    opacity: 0.23;
    float: right;
}
.header-top-search-box{
    width: 204px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 0;
    margin-left: 30px;
    float: right;
}
.header-top-search-button{
    width: 48px;
    height: 40px;
    /* background: linear-gradient(90deg, #2FA82C, #14CC89); */
    background: #217AD5;
    border-radius: none;
    float: right;
    text-align: center;
}
.header-top-search-button img{
    width: 14px;
    height: 14px;
    margin: 13px auto 0;
}
.header-underbox{
    width: 1200px;
    height: 60px;
    margin:0 auto;
    position: relative;
}
.header-underbox-nav{
    height: 57px;
    font-size: 20px;
    /* font-family: STXihei; */
    font-weight: 400;
    color: #FFFFFF;
    line-height: 57px;
    float: left;
    margin-right: 64px;
    border-bottom:3px solid rgba(0,0,0,0);
}
.header-underbox-nav.active{
    border-bottom:3px solid #208b32;
}
/* 分享部分 */
.header-underbox-share-box{
    position: absolute;
    top: 10px;
    right:0;
}
.wechat_showbox{
    position: absolute;
    top: 30px;
    z-index: 500;
    width: 10rem;
    margin-bottom: 1rem;
    border: 0 solid #fff;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
    /* display: none; */
}
.wechat_showbox img{
    display: block;
    width: 100%;
}
.header_hoverDivBox{
    width:203px;
    height:auto;
    background:#fff;
    position:absolute;
    top:60px;
    left:0;
    background: #F7FAFE;
    box-shadow: 0px 2px 15px 0px rgba(14, 81, 151, 0.2);
    z-index:100000;
    padding-top: 21px;
    display: none;
}
.header_hoverDivBox.active{
    display: block;
}
.header_hoverDivBox_item{
    width:100%;
    display:block;
    text-align: left;
    font-weight: 400;
    color: rgb(51, 51, 51);
    font-size: 16px;
    font-weight: 400;
    color: #2C2C2C;
    line-height: 15px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin-bottom: 27px;
    padding-left: 28px;
}
.header_hoverDivBox_item:hover{
    color: #217AD5;
}
.header_hoverDivBox img{
    position: absolute;
    bottom:0;
    left:0;
    display: block;
    width: 100%;
    z-index: -1;
}




</style>
