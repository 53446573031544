import axios from 'axios'
import Qs from 'qs'

// 添加请求拦截器，在请求头中加token
// axios.interceptors.request.use(
//     config => {
//         let usersInfo = localStorage.getItem('usersInfo') ? JSON.parse(localStorage.getItem('usersInfo')) : '';
//         if (usersInfo && usersInfo.token) {
//             config.headers.token = usersInfo.token;
//         } else {
//             config.headers.token = '';
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     })

//设置全局axios默认值
axios.defaults.timeout = 2000000; //20000的超时验证
axios.defaults.withCredentials = true; //跨域带cookie
// axios.defaults.headers.common['SESSIONID'] = SESSIONID;

function http(type, url, contentType, params) {
    let contentTypeUse = contentType == 'json' ? 'application/json' : 'application/x-www-form-urlencoded';
    let paramsUse = contentType == 'json' ? params : Qs.stringify(params);
    if (type == 'get') {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url: url,
                // headers: {'SESSIONID': SESSIONID},
                headers: { 'Content-Type': contentTypeUse },
                params: params
            }).then(res => {

                resolve(res.data)
            })
        })
    } else if (type == 'put') {
        return new Promise((resolve, reject) => {
            axios({
                method: 'put',
                url: url,
                // headers: {'SESSIONID': SESSIONID},
                params: params
            }).then(res => {
                resolve(res.data)
            })
        })
    } else if (type == 'delete') {
        return new Promise((resolve, reject) => {
            axios({
                method: 'delete',
                url: url,
                // headers: {'SESSIONID': SESSIONID},
                params: params
            }).then(res => {
                resolve(res.data)
            })
        })
    } else {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url: url,
                headers: { 'Content-Type': contentTypeUse },
                data: paramsUse
            }).then(res => {
                resolve(res.data)
            })
        })
    }
}

export default http