import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/index.vue'

Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

const routes = [{
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/index',
        name: 'index',
        component: () =>
            import ('@/views/index/index.vue')
    },
    { //交流传播
        path: '/communicate',
        name: 'communicate',
        component: () =>
            import ('@/views/communicate/index.vue')
    },
    { //详情页
        path: '/detail',
        name: 'detail',
        component: () =>
            import ('@/views/detail/index.vue')
    },
    { //关于我们
        path: '/aboutus',
        name: 'aboutus',
        component: () =>
            import ('@/views/aboutus/index.vue')
    },
    { //培训咨询
        path: '/consultation',
        name: 'consultation',
        component: () =>
            import ('@/views/consultation/index.vue')
    },
    { //研究项目
        path: '/research',
        name: 'research',
        component: () =>
            import ('@/views/research/index.vue')
    },
    { //新闻动态
        path: '/information',
        name: 'information',
        component: () =>
            import ('@/views/information/index.vue')
    },
    { //知识产品
        path: '/products',
        name: 'products',
        component: () =>
            import ('@/views/products/index.vue')
    },
    { //搜索列表页
        path: '/searchpage',
        name: 'searchpage',
        component: () =>
            import ('@/views/searchpage/index.vue')
    },
    { //论坛2019
        path: '/luntan2019',
        name: 'luntan2019',
        component: () =>
            import ('@/views/luntan/2019.vue')
    },
    { //论坛2021
        path: '/luntan2021',
        name: 'luntan2021',
        component: () =>
            import ('@/views/luntan/2021.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router